// 
// user-variables.scss
// Use this to override Bootstrap and Cartzilla variables
//

// Example of a variable override to change Cartzilla background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

// Grayscale
$white:                 #fff;
$gray-100:              #f6f9fc;
$gray-200:              #f3f5f9;
$gray-300:              #e3e9ef;
$gray-400:              #dae1e7;
$gray-500:              #aeb4be;
$gray-600:              #7d879c;
$gray-700:              #4b566b;
$gray-800:              #1f2324;
$gray-900:              #12191a;
$black:                 #000;

// Brand colors
$primary:               #71CC98;
$accent:                #002855;
$secondary:             #888B8D;
$info:                  #69b3fe;
$success:               #42d697;
$warning:               #fea569;
$danger:                #f34770;
$light:                 $white;
$dark:                  $gray-800;

// Buttons
$btn-light-color:                   #e3e9ef;

// Grid containers
//
// Modifying default Bootstrap's $container-max-widths map (max-width of .container)
$container-max-widths: (
        sm: 100%,
        md: 100%,
        lg: 100%,
        xl: 1375px
);
